import { NETWORK_MAINNET, NETWORK_POLYGON } from '../../constants';
import { writable } from 'svelte/store';

export const priceRateProvider = writable({
	[NETWORK_MAINNET]: 1858.38,
	[NETWORK_POLYGON]: 0.63025205
});

export async function initCurrencyRates(eth: number, matic: number) {
	priceRateProvider.update((value) => {
		return {
			...value,
			[NETWORK_MAINNET]: eth,
			[NETWORK_POLYGON]: matic
		};
	});
}
